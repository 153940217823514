var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfRange',{staticClass:"form__element--range range-filter",attrs:{"label":("" + (_vm.filter.label)),"config":{
      start: [
        _vm.priceFilterValue[0] || _vm.filter.price_range.from || _vm.filter.price_range.min,
        _vm.priceFilterValue[1] || _vm.filter.price_range.to || _vm.filter.price_range.max ],
      range: {
        min: _vm.filter.price_range.min,
        max: _vm.filter.price_range.max,
      },
      step: _vm.filter.price_range.step,

      connect: true,
      direction: 'ltr',
      orientation: 'horizontal',
      behaviour: 'tap-drag',
      tooltips: true,
      keyboardSupport: true,
      format: {
        to: function (range) { return Math.round(range).toLocaleString('en'); },
        from: function (range) { return Math.round(range.replace(/[^0-9]/g, '')); },
      },
    }},on:{"change":function($event){_vm.$emit('selectFilter', {
      'label': ((Math.round($event[0])) + "-" + (Math.round($event[1]))),
      'value': ((Math.round($event[0].replace(/[^0-9]/g, ''))) + "_" + (Math.round($event[1].replace(/[^0-9]/g, '')))),
      'count': 1,
      '__typename': 'AggregationOption'
      })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }