






































import {
  computed,
  defineComponent,
  inject,
  PropType,
} from '@nuxtjs/composition-api';
import { SfRange } from '~/components';
import type { Aggregation } from '~/modules/GraphQL/types';

export default defineComponent({
  components: {
    SfRange,
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
    priceFilter: {
      type: Array,
      default: [],
    }
  },
  setup(props) {
    const { isFilterSelected } = inject('UseFiltersProvider');
    const selected = computed(() => ((id: string, optVal: string) => isFilterSelected(id, optVal)));

    const priceFilterValue = computed(() => {
      return props.priceFilter?.length ? props.priceFilter[0].toString().split('_') : [];
    })

    return { selected, priceFilterValue };
  },
});
